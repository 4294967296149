body {
  background-color: #ffe8f4 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  cursor: pointer;
}

.App-header {
  background-image: url("resources/Assets/drawable-hdpi/Log in – 1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.centrado-vh {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.centrado-vh-column {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.termyc {
  width: 90%;
}

.gray_txt {
  color: #949494;
}

.bg-pde {
  background-color: #e5097f !important;
}

.bg-pde-gradient {
  /* background: linear-gradient(to right #E5097F, #ffffff) !important; */
  background: linear-gradient(to right, #e5097f, #ef8bb8);
}

.shadow-pde {
  box-shadow: 0px -5px 13px 0px black;
}

.shadow-x {
  box-shadow: 0px 0px 13px -5px black;
}

.shadow-x-2 {
  box-shadow: 0px 0px 10px -5px black;
}

.pde-logo {
  /* margin-left: 100%; */
  width: 2.5rem;
}

.color-pde {
  color: #e5097f !important;
}

.bg-pde-a50 {
  background-color: rgba(229, 9, 126, 0.5);
}

.bg-sugeridos {
  background-color: #f6bdd8;
  /* background-color: rgba(229, 9, 126, 0.56); */
}

.cursor_pointer {
  cursor: pointer;
}

.form-group input {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1.6rem;
  color: #ffffff;
}

.form-group select {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1.6rem;
  color: #ffffff;
}

.arrow-left div {
  font-size: 2rem;
}

.btn-agregarFP {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
  border: 1px dashed #ffffff;
  margin-right: 19%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.btn-agregarFP span {
  position: relative;
  top: -5%;
  left: 1%;
  font-size: 3rem;
}

#fotoperfil {
  display: none;
}

a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.font-size-08 {
  font-size: 0.8rem !important;
}

.font-size-065 {
  font-size: 0.65rem !important;
}

.font-size-075 {
  font-size: 0.75rem !important;
}

.font-size-116 {
  font-size: 1.16rem;
}

.z-index-10 {
  z-index: 10 !important;
}

.titulo {
  border-left: 2px solid #e5097f;
  padding-left: 1%;
  line-height: 0.9 !important;
}

.centrar_absoluto {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

#fechamodal {
  display: none;
  height: 25rem;
  width: 17rem;
  background-color: white;
  z-index: 99;
  border-radius: 2%;
  padding: 1%;
}

#forgotpassmodal {
  display: none;
  height: 18rem;
  /* width: 25rem; */
  background-color: white;
  z-index: 99;
  border-radius: 2%;
  padding: 1%;
}

#legal {
  display: none;
  height: 30rem;
  /* width: 25rem; */
  background-color: white;
  z-index: 99;
  border-radius: 2%;
  /* padding: 1%; */
}

.cont_fechamodal {
  height: 100%;
  width: 100%;
  border: 1.7px dashed rgba(229, 9, 126, 0.5);
  border-radius: 2%;
}

#cerrar_fechamodal {
  position: absolute;
  color: rgba(229, 9, 126, 1);
  /* top: 2%; */
  right: 8%;
  cursor: pointer;
}

.select_date {
  width: 100%;
  height: 100%;
  padding-top: 60%;
  padding-left: 5%;
  padding-right: 5%;
}

/* .dataModal {} */

.acercaimg {
  width: 100%;
  height: 23rem;
  background-image: url("../src/resources/Assets/drawable-hdpi/cabecera.png");
  background-repeat: no-repeat;
  background-size: 140%;
  background-position: center;
  border-radius: 10px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.notaps {
  height: 23rem;
  border-radius: 10px;
  /* box-shadow: 0px 5px 15px -8px black; */
}

.notaps .imagenota {
  height: 82%;
  height: 25rem !important;
}

.datosnota {
  box-shadow: 0px 5px 15px -8px black;
  overflow: hidden;
}

.datosnota .row:nth-child(2) {
  height: 1.4rem;
  overflow: hidden;
}

.datosnota .color-pde {
  font-weight: bold;
}

.notaps .imagenota div:nth-child(1) {
  border-radius: 10px 10px 0px 0px;
  height: 83%;
}

.botones-gd {
  position: absolute;
  top: 3%;
  right: 0%;
  z-index: 99;
  height: 3rem;
  width: 7rem;
  /* height: 10%;
  width: 20%; */
}

.botones-gd .btns-actions {
  height: -webkit-fill-available;
  height: -moz-available;
}

.notaps div:nth-child(1) .portada {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px 10px 0px 0px;
}

.notaps .datosnota {
  border-radius: 0px 0px 10px 10px;
  height: 17%;
}

.notapsmd {
  height: 25rem;
}

.botones-md {
  position: absolute;
  top: 3%;
  right: 0%;
  z-index: 99;
  height: 3rem;
  width: 7rem;
  display: flex;
  /* height: 15%; */
  /* width: 47%; */
}

.botones-md .btns-actions {
  height: -webkit-fill-available;
  height: -moz-available;
}

.botones-ch {
  position: absolute;
  top: 3%;
  right: 0%;
  z-index: 99;
  height: 3rem;
  width: 7rem;
  display: flex;
  /* height: 22%; */
  /* width: 50%; */
}

.botones-ch .btns-actions {
  height: -webkit-fill-available;
  height: -moz-available;
}

.impajuste {
  width: 50% !important;
  height: 100% !important;
  object-fit: contain !important;
}

.notapsmd .portada {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.notapsmd .imagenota {
  border-radius: 10px 10px 0px 0px;
  height: 80%;
}

.notapsmd div:nth-child(2) {
  border-radius: 0px 0px 10px 10px;
  height: 20%;
}

.notapssm {
  height: 18rem;
}

.notapssm .imagenota {
  border-radius: 10px 10px 0px 0px;
  height: 73%;
}

.notapssm div:nth-child(1) .portada {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.notapssm div:nth-child(2) {
  border-radius: 0px 0px 10px 10px;
  height: 27%;
}

.notapssm .imagenota span {
  position: relative;
  top: -95%;
  right: -35%;
}

.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncate-text2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space-between {
  justify-content: space-between;
}

.imagenDetalle {
  width: 100%;
  height: 19rem;
}

.imagenDetalleImg {
  width: 100%;
  height: auto;
}

.imagenDetalleImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.imagenDetalle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

.botonesDetalle {
  position: absolute;
  top: 3%;
  right: 3%;
  z-index: 99;
  height: 20%;
  width: 20%;
}

.btns-actions {
  height: -webkit-fill-available;
  height: -moz-available;
  /* height: 100% !important; */
}

#backPS {
  width: 0.5rem;
  height: 1rem;
  z-index: 99;
  top: 1px;
  left: -1%;
}

#backPS img {
  height: 100%;
  width: 100%;
}

#autor {
  top: 87%;
  right: 7%;
  width: 4rem;
  height: 4rem;
}

#autor img {
  object-fit: cover;
  border-radius: 100%;
}

.text_notaSimilar {
  font-size: .53rem;
  font-weight: bold;
}

#similaresDetalle {
  /* margin-left: -7.8% !important;
  margin-right: -7.8% !important; */
  margin-left: -3rem !important;
  margin-right: -3rem !important;
  height: auto;
  padding-left: 10%;
  padding-right: 10%;
  /* padding-left: 15%;
  padding-right: 15%; */
  /* width: 100%; */
}

.nota-sugerido {
  background-color: white;
  width: 60%;
  height: 7rem;
  border-radius: 5px 5px 5px 5px;
}

.nota-sugeridoPS {
  background-color: white;
  width: 60%;
  height: 7rem;
  border-radius: 5px 5px 5px 5px;
}

.img_sugerido {
  height: 60%;
}

.img_sugerido img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.datos_sugerido {
  height: 40%;
  background-color: #f398c5;
  border-radius: 0px 0px 5px 5px;
}

.datos_sugerido div:nth-child(1) {
  height: 65%;
  max-height: 65%;
  overflow: hidden;
}

.reproductor {
  /* width: 16%; */
  /* height: 22%; */
  height: 4rem;
  width: 4rem;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.32);
  border-radius: 100%;
  /* border: 3px solid white; */
}

.arrowRepr {
  font-size: 3rem;
  color: white;
  position: absolute !important;
  top: -10% !important;
  left: 32% !important;
}

.reproductormd {
  /* width: 22%; */
  /* height: 16%; */
  width: 4rem;
  height: 4rem;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.32);
  border-radius: 100%;
  /* border: 3px solid white; */
}

.arrowReprmd {
  font-size: 3rem;
  color: white;
  position: absolute !important;
  top: -9% !important;
  left: 30% !important;
}

.reproductorch {
  /* width: 22%; */
  /* height: 22%; */
  width: 4rem;
  height: 4rem;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.32);
  border-radius: 100%;
  /* border: 3px solid white; */
}

.arrowReprch {
  font-size: 3rem;
  color: white;
  position: absolute !important;
  top: -9% !important;
  left: 30% !important;
}

.video {
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.categoria {
  width: 25%;
  box-shadow: 0px 2px 5px -2px grey;
  border-radius: 4px;
  float: left;
  flex: none;
}

#categorias {
  height: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  /* justify-content: center; */
  padding: 1%;
  scrollbar-color: #e5197e white;
  scrollbar-width: thin;
}

#categorias::-webkit-scrollbar {
  height: 2px;
  background-color: #ffffff;
}

#categorias::-webkit-scrollbar-thumb {
  background-color: #e5197e;
}

.inst-unete {
  scrollbar-color: #e5197e white;
  scrollbar-width: thin;
}

.inst-unete::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #ffffff;
}

.inst-unete::-webkit-scrollbar-thumb {
  background-color: #e5197e;
}

.overflow-y-auto {
  overflow-y: auto;
}

.notapsmd .imagenotaVS {
  height: 75% !important;
}

.notapsmd .datosnotaVS {
  height: 25% !important;
}

.notapssm .imagenotaVS {
  height: 65% !important;
}

.notapssm .datosnotaVS {
  height: 35% !important;
}

.line_height_75 {
  line-height: 75%;
}

.tituloNota {
  height: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.backimg {
  height: 1.3rem;
  display: inline-block;
}

.legaltitl {
  display: inline-block;
  vertical-align: middle;
  /* margin-bottom: 0; */
}

.cont-titl-legal {
  float: left;
}

.selecttermn {
  /* width: 65%; */
  width: 67%;
  height: 2rem;
  margin: 0 auto;
  border: 1px solid #e5097f;
  border-radius: 15px;
  color: #e5097f;
}

.center_Text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectAviso {
  border-radius: 13px;
  transition: 500ms;
}

.selectAviso.active {
  background: #e5097f;
  /* border-radius: 13px; */
  color: #ffffff !important;
}

.selectTermyc {
  border-radius: 13px;
  transition: 500ms;
}

.selectTermyc.active {
  background: #e5097f;
  /* border-radius: 13px; */
  color: #ffffff !important;
}

.termnbox {
  width: 100%;
  height: 13rem;
  overflow-y: auto;
  color: black;
}

.termnbox::-webkit-scrollbar {
  /* height: 2px; */
  width: 2px;
  background-color: #ffffff;
}

.termnbox::-webkit-scrollbar-thumb {
  background-color: #e5197e;
}

.terminos {
  display: none;
}

.aviso {
  display: block;
}

.cont_unet {
  width: 100%;
  box-shadow: 0px 3px 12px -7px black;
  /* height: 19rem; */
  border-radius: 5px;
}

.cont_checat {
  width: 100%;
  box-shadow: 0px 3px 12px -7px black;
  /* height: 19rem; */
  border-radius: 5px;
}

.unete_box {
  width: 100%;
  height: 9.5rem;
}

.box_shadow {
  box-shadow: 0px 3px 11px -7px black;
}

.border_radius {
  border-radius: 5px;
}

.border_radius_100 {
  border-radius: 100%;
}

.img_unete {
  width: 100%;
  height: 75%;
  background-color: white;
}

.object_fit_cover {
  object-fit: cover;
}

.object_fit_contain {
  object-fit: contain;
}

.wrap_unete {
  width: 90%;
}

.wrap_checate {
  width: 90%;
  margin: auto;
}

.img_checate {
  height: 3rem;
  width: 3rem;
}

.horizontal_center {
  display: flex;
  align-items: center;
}

.circle_deploy {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 100%;
}

.circle_deploy img {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.circle_deploy_active {
  -webkit-transform: rotate(-270deg) !important;
  -moz-transform: rotate(-270deg) !important;
  -ms-transform: rotate(-270deg) !important;
  transform: rotate(-270deg) !important;
}

.img_perfil {
  height: 42px;
  width: 42px;
  /* padding: 6%; */
}

.img_perfil img {
  object-fit: cover;
  border-radius: 100%;
  height: 80%;
  width: 80%;
  transition: 400ms;
}

.hr_pde {
  border: 0.5px solid #e5097f;
}

.notasug {
  width: 14rem;
}

.notasug_2 {
  width: 9rem;
}

.imgchecate {
  width: 7%;
  height: 100%;
  padding: .5%;
}

.datachecate {
  width: 93%;
  height: 100%;
}

.icons_pde {
  width: 1rem !important;
  height: 1rem !important;
}

.icons_pde_ch {
  width: .75rem !important;
  height: .75rem !important;
}

.right-0 {
  right: 0%;
}

.arrow_right {
  transform: rotate(180deg);
}

.padding_1 {
  padding: 1%;
}

.padding_detalle {
  padding: .80rem;
}

#descDetalle a {
  color: #0056b3 !important;
}

/* .CatTxt {
  color: gray;
} */

@media (max-width: 767.98px) {
  #similaresDetalle {
    margin-left: -1.8% !important;
    margin-right: -1.8% !important;
  }
  .nota-sugerido {
    width: 95%;
  }
  .nota-sugeridoPS {
    width: 120%;
  }
  .botones-gd, .botonesDetalle {
    height: 15%;
    width: 25%;
  }
  .comunidad_toggle {
    flex-direction: column !important;
  }
}

.comunidad_toggle {
  flex-direction: inherit;
}

.bg-bienvenidaCR {
  background-image: url("../src/resources/Assets/ctr.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 35rem;
  width: 100%;
  border-radius: 13px;
  padding: 5%;
}

.flex_column {
  flex-direction: column;
}

.cont_img {
  width: 20%;
  height: 5%;
}

.cont_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cont_arrow {
  width: 2%;
  height: auto;
}

.cont_arrow img {
  filter: invert(1);
  transform: rotate(270deg);
}

.box_question {
  min-height: 3rem;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  padding: 1%;
  text-align: center;
  vertical-align: middle;
}

.cont_box_ask {
  width: 60%;
  min-height: 5rem;
  border-radius: 5px;
  padding: 1%;
}

.box_ask {
  border-radius: 5px;
  border: 1.5px dashed #e61a85;
  position: relative;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
}

.box_ask img {
  width: 4.5rem;
  left: 0;
  right: 0;
  /* top: -93%; */
  top: -4rem;
}

.ask {
  color: #e5097f;
  border: 1px solid #e61a85;
  border-radius: 15px;
  padding: 2% 0% 2% 0%;
  text-align: center;
  cursor: pointer;
}

.ask input {
  width: 100%;
  border: 0px;
  border-radius: 15px;
  text-align: center;
}

.ask:hover {
  background-color: #e61a85;
  color: white;
}

/* .ask_active input {
  width: 100%;
  border: 0px;
  border-radius: 15px;
  text-align: center;
} */

.ask_active {
  background-color: #e61a85 !important;
  color: white !important;
}

.ask2 {
  color: #e5097f;
  border: 1px solid #e61a85;
  border-radius: 15px;
  padding: 2% 0% 2% 0%;
  text-align: center;
  cursor: pointer;
}

.ask2 input {
  width: 100%;
  border: 0px;
  border-radius: 15px;
  text-align: center;
}

.header_MiPerfil {
  /* border-bottom-left-radius: 15rem 25px;
  border-bottom-right-radius: 15rem 25px; */
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_edit {
  width: 1.5rem;
  position: absolute;
  right: 2%;
  top: 15%;
  cursor: pointer;
  filter: invert(1);
  /* background-color: #fff;
  -webkit-mask-image: url('./resources/edit-regular.svg');
  mask-image: url('./resources/edit-regular.svg'); */
}

.imgPerfil_edit_cont {
  position: absolute;
  width: 7.5rem;
  height: 7.5rem;
  background-color: white;
  border-radius: 100%;
  top: -4rem;
  left: 0;
  right: 0;
  padding: 1%;
  box-shadow: 0px 0px 7px -4px black;
}

.imgPerfil_edit {
  background-color: #ea4c9b;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  box-shadow: 0px 0px 10px -2px black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cerrar_sesion {
  font-weight: bold;
  color: #e5097f;
  cursor: pointer;
  text-decoration: underline;
}

.flex_space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check_toggle_back {
  width: 2rem;
  height: .4rem;
  background-color: #9c9c9c;
  border-radius: 5px;
  position: relative;
  transition: 800ms;
}

.check_toggle_circle {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0px 0px 6px -2px black;
  top: -70%;
  cursor: pointer;
  -webkit-transition: right 2s;
  transition: 400ms;
  right: 55%;
}

.cont_perfil {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit_photo {
  cursor: pointer;
  padding: 3.5%;
  height: 1.75rem;
  width: 1.75rem;
  background-color: #e61c86;
  border-radius: 100%;
  border: 1px solid white;
  position: absolute;
  bottom: 10%;
  right: 10%;
}

.edit_photo label {
  margin-bottom: 0;
  height: inherit;
  cursor: pointer;
}

.icon_white {
  filter: invert(1);
}

.edit_input {
  border: 1px solid #f39cca;
  font-size: 0.75rem;
}

.edit_checkbox_border {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #e61c86;
  border-radius: 5px;
  cursor: pointer;
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_checkbox_border path {
  color: #e61c86;
}

.edit_guardar {
  display: flex;
  justify-content: center;
}

.edit_guardar div {
  border-radius: 5px;
  background-color: #e5097f;
  padding: 1% 13% 1% 13%;
  width: max-content;
  cursor: pointer;
  color: white;
}

.react-calendar__tile--active {
  background-color: #ea4c9b !important;
}

.react-date-picker__wrapper {
  border: unset !important;
  width: 100%;
}

.react-date-picker__button {
  display: none;
}

.opacity_img {
  opacity: .5 !important;
}

.cerrar_sesion_modal {
  height: 24rem;
  /* background-color: white; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
}

.cont-cerrar-sesion {
  background-color: white;
  border-radius: 7px;
  padding: 2%;
}

.cont-cs {
  border-radius: 7px;
  border: 1.7px dashed #ed71b4;
}

.cont-cs img {
  height: auto;
  object-fit: contain;
}

.cerrar_modal {
  font-weight: bold;
  position: absolute;
  top: 2%;
  right: 3%;
  cursor: pointer;
}

.btn_cerrar_s_cont {
  justify-content: space-around;
}

.btn_cerrar_s_cont div {
  background: #e5097f;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.react-date-picker__inputGroup__input {
  height: 100% !important;
}

.datepicker_signin {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1.6rem;
  color: #ffffff;
  padding: .375rem .75rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding-top: .2% !important;
  padding-bottom: .7% !important;
}

.border_dotted {
  border-radius: 5px;
  border: 2px dotted #e5097f;
}

.border_dashed {
  border-radius: 5px;
  border: 1.5px dashed #e5097f;
}

.reportar {
  background-color: white;
  color: #e73491;
  transition: 300ms;
}

.reportar.active {
  background-color: #e73491;
  color: white;
  border-radius: 3rem;
}

.reportarBtn {
  border-style: solid;
  border-color: #80808052;
}

.arrow_chkt {
  transition: 500ms;
}

.newMessage {
  animation-duration: 1500ms;
  animation-name: nuevoMensaje;
}

@keyframes nuevoMensaje {
  from {
    background-color: #e23fda73;
  }
  to {
    background-color: #4ae23f00;
  }
}

/* Calendario */

.cal_icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */

.cal_row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.cal_row-middle {
  align-items: center;
}

.cal_col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.cal_col-start {
  justify-content: flex-start;
  text-align: left;
}

.cal_col-center {
  justify-content: center;
  text-align: center;
}

.cal_col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.inp_disabled {
  background-color: rgb(235, 235, 228);
}

select.react-date-picker__inputGroup__month {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.firstVS {
  color: #E5097F;
}

.absolut_center {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.menuCalendario {
  transform: rotate(-90deg) translate(5.5rem) rotate(90deg);
}

.menuCalendario1 {
  animation: animMenu1 2s linear forwards;
}

@keyframes animMenu1 {
  from {
    transform: rotate(-90deg) translate(5.5rem) rotate(90deg);
  }
  to {
    transform: rotate(-30deg) translate(5.5rem) rotate(30deg)
  }
}

.menuCalendario2 {
  animation: animMenu2 2s linear forwards;
}

@keyframes animMenu2 {
  from {
    transform: rotate(-90deg) translate(5.5rem) rotate(90deg);
  }
  to {
    transform: rotate(30deg) translate(5.5rem) rotate(-30deg)
  }
}

.menuCalendario3 {
  animation: animMenu3 2s linear forwards;
}

@keyframes animMenu3 {
  from {
    transform: rotate(-90deg) translate(5.5rem) rotate(90deg);
  }
  to {
    transform: rotate(90deg) translate(5.5rem) rotate(-90deg)
  }
}

.menuCalendario4 {
  animation: animMenu4 2s linear forwards;
}

@keyframes animMenu4 {
  from {
    transform: rotate(-90deg) translate(5.5rem) rotate(90deg);
  }
  to {
    transform: rotate(150deg) translate(5.5rem) rotate(-150deg)
  }
}

.menuCalendario5 {
  animation: animMenu5 2s linear forwards;
}

@keyframes animMenu5 {
  from {
    transform: rotate(-90deg) translate(5.5rem) rotate(90deg);
  }
  to {
    transform: rotate(210deg) translate(5.5rem) rotate(-210deg)
  }
}

@keyframes fadein {
  from {
    opacity: 0.0;
  }
  to {
    opacity: 1.0;
  }
}

.lineabottom {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s;
}

.lineabottom:hover {
  background-size: 100% 2px;
}

.font-size-small {
  font-size: small;
}

.btn-invitado {
  background-color: white !important;
  color: #e5097f !important;
}

input:focus, input.form-control:focus {

  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
/* mensaje de chat input */

#enviaMensajeChat:disabled{
  background-color:#ececec!important;
}
